import React from 'react'
import OurBestSellersstyles from './OurBestSellers.module.css'
import BookCard from '../../../../Components/BookCard/BookCard'

const apiforimagesBaseUrl = process.env.REACT_APP_IMAGES_CLOUDFRONT_URL;


const booksData = [
    { bookId: 'Astronaut', title: 'I Want To Be An Astronaut',rating: 5,category: 'Astronaut', price: 14.95,  imageUrl: `${apiforimagesBaseUrl}/BookCovers/Book1.webp` },
    { bookId: 'Doctor', title: 'I Want To Be A Doctor', rating: 5,price: 14.95, category: 'Doctor',  imageUrl: `${apiforimagesBaseUrl}/BookCovers/docterboy.webp` },
    { bookId: 'Firefighter', title: 'I Want To Be A Firefighter',rating: 5, price: 14.95, category: 'Firefighter', imageUrl: `${apiforimagesBaseUrl}/BookCovers/firefightergirl_cover.webp` },
    { bookId: 'Youtuber', title: 'I Want To Be A Youtuber',rating: 5, price: 14.95, category: 'Youtuber',  imageUrl: `${apiforimagesBaseUrl}/BookCovers/youtubegirl.webp` }
    // Add more books as needed
  ];

const OurBestSellers = ({titleColor,subheading}) => {
  return (
    <div className={OurBestSellersstyles.main}>
        <h1 style={{color: titleColor}} className={OurBestSellersstyles.mainheading}>Our Bestsellers</h1>
        <p style={{color: subheading}} className={OurBestSellersstyles.subheading}>Personalised stories loved by millions</p>
      <div className={OurBestSellersstyles.bookContainer}>
        {booksData.map((book) => (
          // <BookCard
          //   key={book.bookId}
          //   title={book.title}
          //   price={book.price}
          //   author={book.author}
          //   imageUrl={book.imageUrl}
          // />
          <div key={book.bookId}>
            <BookCard
                  product={book}
                />
         </div>
        ))}
        
      </div>
    </div>
  )
}

export default OurBestSellers