import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import styles from "./StripePayment.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setcolor, setIcon, setmessage, setsize, settoaststate, setuniqueId, setvariant } from "../../features/toastSlice";
import LoadingModal from '../LoadingModal/LoadingModal';
// Load your Stripe publishable key
import { useNavigate } from 'react-router-dom';
import OrderConfirmationModal from '../OrderConfirmationModal/OrderConfirmationModal';
import { clearcart } from '../../features/cart/cartSlice';
import { post } from 'aws-amplify/api';

const lambdaURL = process.env.REACT_APP_LAMBDA_URL;
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const stripepaymenturl = process.env.REACT_APP_STRIPE_PAYMENT_ENDPOINT
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

// const stripePromise = loadStripe('pk_test_51PknXP00bw8PoyMKNi5mbMzm0tiLfl73cnriFf1C4PGGPY5R4buAEb021IkT89LfrfkAe9bZWaVsKzOSTMWygH1M00WYWhVq41');

const CheckoutForm = ({ clientSecret, fields, totalCount, details, cart, setErrors, tax,country, finalTotalPrice, totalBasePrice, setDetails }) => {

    const {cart:{isPromoapplied, voucherPromoCode}} = useSelector((cart)=>cart)
    
    console.log("top country", country);

    const stripe = useStripe();
    const elements = useElements();
    const [paymentError, setPaymentError] = useState(null);
    const [paymentSuccess, setPaymentSuccess] = useState(null);
    const { gender, book, eyeColor, skincolor, hairstyle, haircolor, town, childname } = useSelector((state) => state.characterinfo);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const [progressbar, setProgressbar] = useState({
        step1: true,
        step2: false,
        step3: false,
        step4: false
    });
    const steps = ['Payment', 'Creating Your Book', 'Order Completed', 'Finalizing']

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [openOrderModal, setOpenOrderModal] = useState(false);

    const handleOpenOrderModal = () => setOpenOrderModal(true);

    const handleCloseOrderModal = () => {
        setOpenOrderModal(false);
        navigate('/');
        // window.location.reload();
    };

    const dispatch = useDispatch();

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const generateRandom6DigitNumber = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < 8; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters[randomIndex];
        }
        return result;
    };

    const validateFields = () => {
        const errors = {};
        let valid = true;

        if (!fields.firstName) {
            errors.firstName = "First Name cannot be empty";
            valid = false;
        }
        if (!fields.lastName) {
            errors.lastName = "Last Name cannot be empty";
            valid = false;
        }
        if (!fields.AddressLine1) {
            errors.AddressLine1 = "Address Line 1 cannot be empty";
            valid = false;
        }

        if (!fields.City) {
            errors.City = "County/Town cannot be empty";
            valid = false;
        }
        if (!fields.ZipCode) {
            errors.ZipCode = "Postcode cannot be empty";
            valid = false;
        }
        if (!fields.PhoneNumber) {
            errors.PhoneNumber = "Phone Number cannot be empty";
            valid = false;
          }
        if (!fields.Email || !/\S+@\S+\.\S+/.test(fields.Email)) {
            errors.Email = "Invalid email address";
            valid = false;
        }
        setErrors(errors);
        return valid;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setButtonLoader(true);
        setPaymentSuccess(null);
        setPaymentError(null);

        if (!stripe || !elements) {
            setButtonLoader(false);
            return;
        }

        if (!validateFields()) {
            setButtonLoader(false);
            // console.log("Form submitted with:", fields);
            return;
        }

        if (totalCount < 1) {
            showToast('You do not have any item in cart', 'warning');
            return;
        }

        const missingFields = validateAPIFields(fields);

        if (missingFields.length > 0) {
            showToast('Validation failed: Missing or empty fields:', 'warning');
            console.error('Validation failed: Missing or empty fields:', missingFields);
            setButtonLoader(false);
            return;
        }
        setProgressbar(prev => ({ ...prev, step1: true }))
        try {
            const { error, paymentIntent } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: 'https://iwant2bea.com/contactus',
                },
                redirect: 'if_required',
            });

            if (error) {
                handlePaymentError(error);
            } else if (paymentIntent.status === 'succeeded') {
                handlePaymentSuccess(paymentIntent);
            } else if (paymentIntent.status === 'requires_action') {
                handle3DSAuthentication(paymentIntent);
            } else {
                setPaymentError('Payment failed!');
                showToast('Payment failed! Please try again.', 'danger');
            }
        } catch (err) {
            console.error('Payment error:', err);
            setPaymentError('An error occurred. Please try again.');
        } finally {
            setButtonLoader(false);
        }
    };

    const handlePaymentError = (error) => {
        // console.log('[error]', error);
        setPaymentError(error.message);
    };

    const validateAPIFields = (fields) => {
        const requiredFields = [
            'firstName','lastName', 'AddressLine1', 'City', 'Email', 'PhoneNumber', 'ZipCode'
        ];

        const missingFields = requiredFields.filter(field => !fields[field] || fields[field].trim() === '');

        return missingFields;
    };

    const redeemVoucher = async () => {
        try{  
        const res = await fetch(`${apiBaseUrl}/items/redeem-voucher`, {
            method: 'PUT',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                PK: voucherPromoCode
            }),
          });
          console.log("res",res)
          const data = await res.json();
          console.log("data",data)
          return data;
    }catch(err){
            console.error("error",err)
    }
    }

    const handlePaymentSuccess = async (paymentIntent) => {
        // console.log("Payment successful!", paymentIntent);
        setPaymentSuccess('Payment successful!');

        console.log("Is promo applied?", isPromoapplied);

        setOpen(true);

        if (isPromoapplied) {
            // console.log("Redeeming voucher...");
            const res = await redeemVoucher();
            if (!res.success) {
                console.log("Voucher failed");
                setOpen(false);
                showToast('Voucher Code Invalid or Expire', 'danger');
                return; // Stop execution here
            }
            console.log("Voucher saved successfully");
        } else {
            console.log("Promo is not applied, skipping voucher redemption.");
        }
        
        setProgressbar(prev => ({ ...prev, step2: true }))

        const formdata = createFormData(paymentIntent);

        try {
            const response = await fetch(`${apiBaseUrl}/items/add-order`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formdata)
            });

            if (!response.ok) {
                throw new Error('Failed to place order');
            }

            const result = await response.json();

            if (result.success === "Order added successfully") {
                const transformedCart = await transformCart(cart, result?.data?.PK);
                await handleBookPrinting(transformedCart,result);

                setDetails({ orderDetails: result.data, paymentDetails: null });
            }
        } catch (error) {
            console.error('Order error:', error);
            showToast('Something went wrong creating Book! Please try again', 'danger');
            setOpen(false)
        }
    };

    const showToast = (message, color) => {
        dispatch(settoaststate(true));
        dispatch(setsize('sm'));
        dispatch(setvariant('soft'));
        dispatch(setcolor(color));
        dispatch(setmessage(message));
        dispatch(setIcon(color));
    };

    async function transformCart(cart, orderId) {
        // Extract book details from the cart
        const bookDetails = cart.map(item => item.variation);
    
        // Construct the transformed cart object
        const transformedCart = {
            "order-details": {
                "order-id": orderId
            },
            "book-details": bookDetails
        };
    
        return transformedCart;
    }
    
    const createFormData = (paymentIntent) => {
        const randomid = generateRandom6DigitNumber();
        const formattedDate = formatDate(new Date());

        const customerName = localStorage.getItem('CurrentFullName') || 'guest';
        const customerID = localStorage.getItem('bookstorecurrentloginuser') || `guestUser-${Date.now().toString(36)}`;

        const actualTotalAmount = Number(finalTotalPrice).toFixed(2)

        const shippingDetail = {
            firstName: fields.firstName || 'N/A',
            lastName: fields.lastName || 'missing',
            AddressLine1: fields.AddressLine1 || 'N/A',
            CountyTown: fields.City || 'N/A',
            AptSuiteUnit: fields.aptunit || '',
            City: fields.City || 'N/A',
            Email: fields.Email || 'N/A',
            PhoneNumber: fields.PhoneNumber || 'N/A',
            Postcode: fields.ZipCode || 'N/A',
            isoCountry: 'GB',
        };
        
        return {
            PK: `orderId-${randomid}`,
            SK: 'order',
            orderDate: formattedDate,
            customer: { name: customerName, id: customerID },
            item: cart.length > 0 ? cart : 'missing',
            paymentDetails: {
                itemPrice: totalBasePrice || 'missing',
                tax: tax || 'missing',
                totalAmount: actualTotalAmount || 'missing',
                paymentID: paymentIntent.id || 'missing',
                status: paymentIntent.status || 'missing',
                orderCountry: country || 'missing',
            },
            shippingDetail,
            amount: actualTotalAmount,
            voucherPromoCode: voucherPromoCode || null
        };
    };

    const handleBookPrinting = async (transformedCart,result) => {
        setProgressbar(prev => ({ ...prev, step3: true }))

        try {
            const response = await fetch(lambdaURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(transformedCart)
            });

            if (!response.ok) {
                throw new Error('Failed to send book printing data');
            }

            localStorage.removeItem('cart');
            await dispatch(clearcart());
            setOpen(false)
            navigate('/order-confirm', { state: { orderDetails: result.data } })
            setProgressbar(prev => ({ ...prev, step4: true }))
            setOpenOrderModal(true)
        } catch (error) {
            console.error('Book printing error:', error);
            showToast('Something went wrong! try again', 'danger');
            setOpen(false)
        } 
    };

    const handle3DSAuthentication = async (paymentIntent) => {
        const { error } = await stripe.confirmCardPayment(paymentIntent.client_secret);

        if (error) {
            setPaymentError(error.message);
        } else {
            setPaymentSuccess('Payment successful!');
            handlePaymentSuccess(paymentIntent);
        }
    };

    return (
        <div>
            <form className={styles["form-container"]} onSubmit={handleSubmit}>
                <PaymentElement />
                <button type="submit" disabled={!stripe} className={styles.styledButton}>
                    {buttonLoader ? <div className={styles.loader}></div> : 'Pay'}
                </button>

                {paymentError && <div className={styles["error"]} >{paymentError}</div>}
                {paymentSuccess && <div className={styles["success"]}  >{paymentSuccess}</div>}

            </form>
            <LoadingModal text='Payment Successful' open={open} handleClose={handleClose} progressbar={progressbar} steps={steps}/>
            <OrderConfirmationModal open={openOrderModal} handleClose={handleCloseOrderModal} />

        </div>
    );
};

const StripePayment = ({ fields, totalCount, details, cart, setErrors, finalTotalPrice,tax,country, totalBasePrice, setDetails, setClientSecret, clientSecret, setOpenBackdrop }) => {
    const actualTotalAmount = finalTotalPrice.toFixed(2);
    // Convert to integer by multiplying by 100 and rounding
    const actualTotalAmountAsNumber = Math.round(parseFloat(actualTotalAmount) * 100);

    useEffect(() => {
        // Fetch the client secret from your server when the component mounts
        const fetchClientSecret = async () => {
            const response = await fetch(stripepaymenturl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ amount: actualTotalAmountAsNumber }), // Pass amount or other details as needed
            });
            const { clientSecret } = await response.json();
            // console.log("clientSecret", clientSecret)
            setOpenBackdrop(false);
            setClientSecret(clientSecret);
        };

        fetchClientSecret();
    }, []);

    return clientSecret ? (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm setDetails={setDetails} tax={tax} country={country} clientSecret={clientSecret} totalCount={totalCount} fields={fields} details={details} cart={cart} totalBasePrice={totalBasePrice} finalTotalPrice={finalTotalPrice} setErrors={setErrors} />
        </Elements>
    ) : (
        <div></div>
    );
};

export default StripePayment;
