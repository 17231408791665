import React, { useEffect, useState } from 'react';
import styles from './AllCustomerOrders.module.css';
import DetailsComponent from './DetailsComponent';
import { MdRefresh } from "react-icons/md";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const AllCustomerOrders = ({ orders, setPopupData, fetchOrders, setLoading }) => {
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(5);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortAsc, setSortAsc] = useState(false);

  console.log("orders",orders)
  
  useEffect(() => {
    // Sort orders by date (latest first) whenever the orders prop changes
    // This part can be kept if you want to sort on initial load
    orders.sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate));
  }, [orders]);

  // Filter orders based on the search term
  const filteredOrders = orders.filter(order =>
    order.customer?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    order.PK.toString().includes(searchTerm)
  );

  // Sort filtered orders based on the current sort state
  const sortedOrders = [...filteredOrders].sort((a, b) => {
    if (sortAsc) {
      return new Date(a.orderDate) - new Date(b.orderDate); // Ascending
    } else {
      return new Date(b.orderDate) - new Date(a.orderDate); // Descending
    }
  });

  // Calculate pagination
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = sortedOrders.slice(indexOfFirstOrder, indexOfLastOrder);
  const totalPages = Math.ceil(sortedOrders.length / ordersPerPage);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleViewDetails = (order) => {
    setSelectedOrder(order);
    setShowDetail(true);
  };

  const handleRefreshOrders = async () => {
    setLoading(true);
    await fetchOrders();
    setLoading(false);
  };

  const toggleSort = () => {
    setSortAsc(!sortAsc); // Toggle sort state
  };

  const handleClose = () => {
    setShowDetail(false)
  }

  const getPagination = () => {
    const pageNumbers = [];
    if (totalPages <= 5) {
      // If total pages are less than or equal to 5, show all page numbers
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // If total pages are more than 5, create a truncated pagination
      if (currentPage <= 3) {
        pageNumbers.push(1, 2, 3, 4, '...', totalPages);
      } else if (currentPage > totalPages - 3) {
        pageNumbers.push(1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
      } else {
        pageNumbers.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
      }
    }
    return pageNumbers;
  };

  const handlePageClick = (pageNumber) => {
    if (pageNumber === '...') return; // Do nothing when '...' is clicked
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (e) => {
    const trimmedSearchTerm = e.target.value.trim();
    setSearchTerm(trimmedSearchTerm)
    setCurrentPage(1);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className={styles.orderList}>
      {/* <h2 className={styles.titleh2}>Orders</h2>
      <div className={styles.topcontrolls}>
        <span onClick={handleRefreshOrders}><MdRefresh /></span>
        <input
          type="text"
          placeholder="Search by Customer Name or Order ID"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className={styles.searchInput} // Add CSS for styling if needed
        />
        <button onClick={toggleSort}>
          Sort by Date {sortAsc ? '↑' : '↓'}
        </button>
      </div> */}

<div className={styles.filterContainer}>
        <span className={styles.heading}>All Orders</span>
        {orders.length > 0 && <div className={styles.filter}>
          {/* <div onClick={handleRefreshOrders} className={styles.refresh}>
          <MdRefresh/>
          </div> */}
        <input
          type="text"
          placeholder="Search by Order ID"
          value={searchTerm}
          onChange={handleSearchChange}
          className={styles.searchInput} // Add CSS for styling if needed
        />
         <div onClick={toggleSort} className={styles.togglebutton}>
          Sort by Date {sortAsc ? '↑' : '↓'}
        </div>
        </div>}
      </div>

      {currentOrders.length > 0 ? (
        <>
          <table className={styles.voucherTable}>
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Customer Name</th>
                <th>Amount</th>
                <th>Order Date</th>
                <th>Status</th>
                <th>PromoCode</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {currentOrders.map((order) => (
                <tr key={order.PK}>
                  <td>{order.PK}</td>
                  <td>{order.customer?.name}</td>
                  <td>{order.amount}</td>
                  <td>{order.orderDate}</td>
                  <td>
                   {order.currentStatus ? <span className={`${order.currentStatus.status === 'shipped' ? styles.successclas : styles.received}`}>
                      {order.currentStatus?.status}
                    </span>:
                    <span className={`${order.paymentDetails.status === 'succeeded' ? styles.successclas : 'successclas'}`}>
                    {order.paymentDetails?.status}
                  </span>
                    }
                  </td>
                  
                  <td>{order.voucherPromoCode}</td>
                  <td>
                    <span className={styles.fulldetailbtn} onClick={() => handleViewDetails(order)}>View</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className={styles.pagination}>
            {/* {[...Array(totalPages)].map((_, i) => (
              <button
                key={i + 1}
                onClick={() => handlePageChange(i + 1)}
                className={`${styles.pageItem} ${currentPage === i + 1 ? styles.activePage : ''}`}
              >
                {i + 1}
              </button>
            ))} */}
            <div className={styles.pagination}>
        {getPagination().map((pageNumber, i) => (
          <button
            key={i}
            onClick={() => handlePageClick(pageNumber)}
            className={`${styles.pageItem} ${currentPage === pageNumber ? styles.activePage : ''}`}
            disabled={pageNumber === '...'}
          >
            {pageNumber}
          </button>
        ))}
      </div>
            {showDetail && selectedOrder && (
              <DetailsComponent order={selectedOrder} setShowDetail={setShowDetail} />
            )}
          </div>
        </>
      ) : (
        <p>No order found</p>
      )}
    </div>
  );
};

export default AllCustomerOrders;
