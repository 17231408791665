// VoucherBanner.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import './VoucherBanner.css';

const VoucherBanner = () => {
  return (
    <div className="voucher-banner">
      <FontAwesomeIcon icon={faTag} className="icon" /> {/* Discount icon */}
      <p className="voucher-message">
        Get 20% off your first order using the code <span className='vouchercd'>IW2B1</span> when checking out!
      </p>
      <FontAwesomeIcon icon={faShoppingCart} className="icon" /> {/* Shopping cart icon */}
    </div>
  );
};

export default VoucherBanner;
