import React, { useState, useEffect } from 'react';
import HomeOurBooksStyles from './HomeOurBooks.module.css';

import BookCard from '../../../../Components/BookCard/BookCard';

import santacover from '../../../../Assets/Images/siteImages/santacover.webp'

import { GrFormPrevious } from "react-icons/gr";
import { GrFormNext } from "react-icons/gr";

const apiforimagesBaseUrl = process.env.REACT_APP_IMAGES_CLOUDFRONT_URL;

const booksData = [
  { bookId: 'Santa', title: "I Want To Be Santa's Helper", rating: 5, category: 'Santa', price: 14.95, imageUrl: `${apiforimagesBaseUrl}/BookCovers/santacover.webp` },
  { bookId: 'Astronaut', title: 'I Want To Be An Astronaut',rating: 5, category: 'Astronaut', price: 14.95, imageUrl: `${apiforimagesBaseUrl}/BookCovers/Book1.webp` },
  { bookId: 'Doctor', title: 'I Want To Be A Doctor', rating: 5,category: 'Doctor', price: 14.95, imageUrl: `${apiforimagesBaseUrl}/BookCovers/docterboy.webp` },
  { bookId: 'Firefighter', title: 'I Want To Be A Firefighter',rating: 5, category: 'Firefighter', price: 14.95, imageUrl: `${apiforimagesBaseUrl}/BookCovers/firefightergirl.webp` },
  { bookId: 'Footballer', title: 'I Want To Be A Footballer',rating: 5, category: 'Footballer', price: 14.95, imageUrl: `${apiforimagesBaseUrl}/BookCovers/footballergirl.webp` },
  { bookId: 'Youtuber', title: 'I Want To Be A Youtuber',rating: 5, category: 'Youtuber', price: 14.95, imageUrl: `${apiforimagesBaseUrl}/BookCovers/youtubegirl.webp` },
  { bookId: 'VetDoctor', title: 'I Want To Be A Vet', rating: 5,category: 'VetDoctor', price: 14.95, imageUrl: `${apiforimagesBaseUrl}/BookCovers/vetboycover.webp` },

  { bookId: 'TrainDriver', title: 'I Want To Be A Train Driver', rating: 5,category: 'TrainDriver', price: 14.95, imageUrl: `${apiforimagesBaseUrl}/BookCovers/drivergirl.webp` },
  { bookId: 'PoliceOfficer', title: 'I Want To Be A Police Officer', rating: 5,category: 'PoliceOfficer', price: 14.95, imageUrl: `${apiforimagesBaseUrl}/BookCovers/policeboy.webp` },
  // { bookId: 'PoliceOfficer', title: 'I Want To Be A Police Officer', rating: 5,category: '', price: 14.95, imageUrl: `${apiforimagesBaseUrl}/BookCovers/policeboy.webp` },

  { bookId: 'Nurse', title: 'I Want To Be A Nurse',rating: 5, category: 'Nurse', price: 14.95, imageUrl: `${apiforimagesBaseUrl}/BookCovers/nursegirl.webp` },
  { bookId: 'Teacher', title: 'I Want To Be A Teacher', rating: 5,category: 'Teacher', price: 14.95, imageUrl: `${apiforimagesBaseUrl}/BookCovers/teacherboy.webp` },

];

const HomeOurBooks = ({ titleColor }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(4);

  const updateItemsPerPage = () => {
    const width = window.innerWidth;
    if (width <= 637) {
      setItemsPerPage(1);
    } else if (width <= 992) {
      setItemsPerPage(2);
    } else if (width <= 1024) {
      setItemsPerPage(3);
    } 
    else if (width <= 1289) {
        setItemsPerPage(3);
      } 
      else {
      setItemsPerPage(4);
    }
  };

  useEffect(() => {
    updateItemsPerPage();
    window.addEventListener('resize', updateItemsPerPage);
    return () => {
      window.removeEventListener('resize', updateItemsPerPage);
    };
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % booksData.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + booksData.length) % booksData.length);
  };

  const getDisplayedBooks = () => {
    const startIndex = currentIndex;
    const endIndex = (currentIndex + itemsPerPage) % booksData.length;
    if (endIndex > startIndex) {
      return booksData.slice(startIndex, endIndex);
    } else {
      return [...booksData.slice(startIndex), ...booksData.slice(0, endIndex)];
    }
  };

  const displayedBooks = getDisplayedBooks();

  return (
    <div className={HomeOurBooksStyles.main}>
      <h1 style={{ color: titleColor }} className={HomeOurBooksStyles.mainheading}>Our Books</h1>

      <div className={HomeOurBooksStyles.carouselContainer}>
        {/* <button className={HomeOurBooksStyles.arrow} onClick={prevSlide}>&lt;</button> */}
        <div className={HomeOurBooksStyles.prev_btn}>
                <GrFormPrevious onClick={prevSlide} className={HomeOurBooksStyles.carousalBtns} />
              </div>        <div className={HomeOurBooksStyles.carousel}>
          {displayedBooks.map((book) => (
            <div key={book.bookId} className={HomeOurBooksStyles.bookCard}>
              <BookCard product={book} />
            </div>
          ))}
        </div>
        <div className={HomeOurBooksStyles.next_btn}>
                <GrFormNext onClick={nextSlide} className={HomeOurBooksStyles.carousalBtns} />
              </div>
        {/* <button className={HomeOurBooksStyles.arrow} onClick={nextSlide}>&gt;</button> */}
      </div>
    </div>
  );
};

export default HomeOurBooks;
