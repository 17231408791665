import OurBooksStyles from './OurBooks.module.css'
import BookCard from '../BookCard/BookCard';
import { useState } from 'react';

import santacover from '../../Assets/Images/siteImages/santacover.webp'

// import book2Image from '../../Assets/BookCovers/docterboy.webp';
// import book3Image from '../../Assets/BookCovers/firefightergirl.webp';
// import book4Image from '../../Assets/BookCovers/footballergirl.webp';
// import book5Image from '../../Assets/BookCovers/youtubegirl.webp';
// import book6Image from '../../Assets/BookCovers/vetboycover.webp';

// import book7Image from '../../Assets/BookCovers/teacherboy.webp';
// import book8Image from '../../Assets/BookCovers/drivergirl.webp';
// import book9Image from '../../Assets/BookCovers/policeboy.webp';
// import book10Image from '../../Assets/BookCovers/nursegirl.webp';

const apiforimagesBaseUrl = process.env.REACT_APP_IMAGES_CLOUDFRONT_URL;

const booksData = [
  { bookId: 'Santa', title: "I Want To Be Santa's Helper", rating: 5, category: 'Santa', price: 14.95, imageUrl: `${apiforimagesBaseUrl}/BookCovers/santacover.webp` },
  
  { bookId: 'Astronaut', title: 'I Want To Be An Astronaut', rating: 5, category: 'Astronaut', price: 14.95, imageUrl: `${apiforimagesBaseUrl}/BookCovers/Book1.webp` },
  { bookId: 'Doctor', title: 'I Want To Be A Doctor', rating: 5, category: 'Doctor', price: 14.95, imageUrl:  `${apiforimagesBaseUrl}/BookCovers/docterboy.webp` },
  { bookId: 'Firefighter', title: 'I Want To Be A Firefighter',rating: 5, category: 'Firefighter', price: 14.95, imageUrl: `${apiforimagesBaseUrl}/BookCovers/firefightergirl_cover.webp` },
  { bookId: 'Footballer', title: 'I Want To Be A Footballer', rating: 5, category: 'Footballer', price: 14.95, imageUrl: `${apiforimagesBaseUrl}/BookCovers/footballergirl.webp` },
  { bookId: 'Youtuber', title: 'I Want To Be A Youtuber', rating: 5, category: 'Youtuber', price: 14.95, imageUrl: `${apiforimagesBaseUrl}/BookCovers/youtubegirl.webp` },
  { bookId: 'Nurse', title: 'I Want To Be A Nurse', rating: 5, category: 'Nurse', price: 14.95, imageUrl: `${apiforimagesBaseUrl}/BookCovers/nursegirl.webp` },
  { bookId: 'Teacher', title: 'I Want To Be A Teacher', rating: 5, category: 'Teacher', price: 14.95, imageUrl: `${apiforimagesBaseUrl}/BookCovers/teacherboy.webp` },
  { bookId: 'TrainDriver', title: 'I Want To Be A Train Driver', rating: 5, category: 'TrainDriver', price: 14.95, imageUrl: `${apiforimagesBaseUrl}/BookCovers/drivergirl.webp` },
  { bookId: 'PoliceOfficer', title: 'I Want To Be A Police Officer', rating: 5, category: 'PoliceOfficer', price: 14.95, imageUrl: `${apiforimagesBaseUrl}/BookCovers/policeboy.webp` },
  // { bookId: 'PoliceOfficer', title: 'I Want To Be A Police Officer', rating: 5, category: '', price: 14.95, imageUrl: `${apiforimagesBaseUrl}/BookCovers/policeboy.webp` },
  { bookId: 'VetDoctor', title: 'I Want To Be A Vet', rating: 5, category: 'VetDoctor', price: 14.95, imageUrl: `${apiforimagesBaseUrl}/BookCovers/vetboycover.webp` },

  

  // { bookId: 11, title: 'I Want To Be A Teacher', rating: 5, category: '', price: 14.95, imageUrl: book7Image },
  // { bookId: 12, title: 'I Want To Be A Train Driver', rating: 5, category: '', price: 14.95, imageUrl: book8Image },
  // { bookId: 13, title: 'I Want To Be A Police Officer', rating: 5, category: '', price: 14.95, imageUrl: book9Image },
  // { bookId: 14, title: 'I Want To Be A Vet', rating: 5, category: '', price: 14.95, imageUrl: book6Image },
  // Add more books as needed
];

const OurBooks = ({titleColor}) => {
  // const [bookDetail, setbookDetail] = useState([booksData]);
  // console.log("booksData",booksData.imageUrl)
  const [value, setValue] = useState();

  return (
    <>
      {booksData &&
        <div className={OurBooksStyles.main}>
          <h1 style={{color: titleColor}} className={OurBooksStyles.mainheading}>Our Books</h1>

          <div className={OurBooksStyles.bookContainer}>
            {booksData.map((book) => (
              <div key={book.bookId}>
              <BookCard
                product={book}
              />
              </div>
            ))}
          </div>
        </div>
      }
    </>
  );
};

export default OurBooks;
